<template>
  <div>
    <section class="main services">
      <div class="hero parallax"></div>

      <h2>{{ currentRouteName }}</h2>

      <article>
        <div class="thumb"></div>
        <p class="intro">
          GammaTeam Security delivers a broad scope of security services performed by experienced law enforcement professionals who apply industry best practices to meet your specific needs on demand 24 hours day, 7 days a week.<br /><br />

          Our services are our reputation, and we value your business as much as we value ours. Our security measures are taken to ensure safety of our VIPs, celebrities, CEOs or anyone that may be exposed to personal risk based on their employment, wealth, associations or geographical location.
        </p>
      </article>

      <call-to-action :link_back=true></call-to-action>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ExecutiveSecurity'
}
</script>

<style scoped>
section.main article div.thumb {
  background-image: url("/images/services/vip.jpg");
}
</style>
